// import React, { useState, useEffect } from "react";
// import bill02 from "./bill04.png";
// import billtest from "./billtest.jpg";
// import Col from "react-bootstrap/Col";
// import Row from "react-bootstrap/Row";
// import { Container } from "react-bootstrap";
// import Form from "react-bootstrap/Form";
// import Button from "react-bootstrap/Button";
// import Tab from "react-bootstrap/Tab";
// import Tabs from "react-bootstrap/Tabs";
// import Card from "react-bootstrap/Card";
// import Projects from "../Bills/Projects";
// import PageII from "../pages/PageII";
// import dataArray from './Util'
// import { useNavigate } from 'react-router-dom';
// import axios from "axios";

// import { notification, Spin } from 'antd';



// function SliderMain() {
//   const [key, setKey] = useState("home");
//   const [captchaValue, setCaptchaValue] = useState("");
//   const handleCaptchaComplete = (isValid) => {
//     console.log("CAPTCHA is valid:", isValid);
//   };
//   const handleCaptchaChange = (value) => {
//     setCaptchaValue(value);
//   };

//   const [inputValue, setInputValue] = useState("");
//   const [captchaText, setCaptchaText] = useState(generateCaptcha());
//   const [onCaptchaComplete, setCaptchaComplete] = useState("");
//   const [loading, setLoading] = useState(false);
//   const navigate = useNavigate();
//   const [form, setForm] = useState({
//     RegNo: "",
//   });


//   const resetForm = () => {
//     setForm(prevForm => ({ ...prevForm, RegNo: "" }));
//     setInputValue(""); 
//     setCaptchaText(generateCaptcha()); 
//   };


//   const handleInputChange = (event) => {
//     setInputValue(event.target.value);
//   };

//   const handleInput = (e) => {
//     const { name, value } = e.target;
//     setForm({ ...form, [name]: value });
//   };

//   useEffect(() => {
//   }, [form]);


//   const getElectricBill = async () => {
//     setLoading(true); 
//     axios.get(
//       "https://rwp.e-billingbahriatownlahore.com/api/Customer/GetElectrcityBill?ParNewRefrence=" + dataArray["Bill"],
//       { responseType: 'blob' }
//     )
//       .then(response => {
//         if (response.status === 200) {
//           const pdfBlob = new Blob([response.data], { type: 'application/pdf' });
//           const pdfUrl = URL.createObjectURL(pdfBlob);
//           window.open(pdfUrl, '_blank');
//           resetForm();
//           dataArray['Bill'] = ""
//           setInputValue(""); 
//           setCaptchaText(generateCaptcha()); 
//         }
//       })
//       .catch(error => {
//         if (error.response && error.response.status === 404) {
//           notification.error({
//             message: 'Error',
//             description: 'The requested bill was not found. Please enter the correct RegNo.',
//             duration: 5,
//           });
//         }
//         console.error('There was an error downloading the PDF:', error);
//       })
//       .finally(() => {
//         setLoading(false);
//       });
//   };
  





//   const getMaintenanceBill = async () => {
//     setLoading(true); 
//     axios.get(
//       "https://rwp.e-billingbahriatownlahore.com/api/Customer/GetMaintenanceBill?ParNewRefrence=" + dataArray['Bill'],
//       { responseType: 'blob' }
//     )
//       .then(response => {
//         if (response.status === 200) {
//           const contentType = response.headers['content-type'];
//           if (contentType && contentType.toLowerCase().includes('application/pdf')) {
//             const pdfBlob = new Blob([response.data], { type: 'application/pdf' });
//             const pdfUrl = URL.createObjectURL(pdfBlob);
//             window.open(pdfUrl, '_blank');
//             resetForm();
//             setCaptchaText(generateCaptcha()); 
//           }
//         } 
//       })
//       .catch(error => {
//         if (error.response && error.response.status === 404) {
//           notification.error({
//             message: 'Error',
//             description: 'The requested bill was not found. Please enter the correct RegNo.',
//             duration: 5,
//           });
//         }
//         console.error('There was an error downloading the PDF:', error);
//       })
//       .finally(() => {
//         setLoading(false); 
//       });
//   };
  





//   const handleSubmit = (event) => {
//     event.preventDefault();

//     if (inputValue.toLowerCase() === captchaText.toLowerCase()) {
//       setCaptchaComplete(true);
//       dataArray['Bill'] = form.RegNo
//       console.log(dataArray['Bill'])
//       // navigate('/Bill', { replace: true });
//       getElectricBill();
//       // navigate('/EletricityRwp', { replace: true }); 
//       // window.location.href =
//       //   "https://e-billingbahriatownlahore.com/E_BillingHistory/PrintBill?RegNo=" +
//       //   form.RegNo;
//     } else {
//       setCaptchaComplete(false);
//       // Reset CAPTCHA
//       setInputValue("");
//       setCaptchaText(generateCaptcha());
//     }
//   };



//   const handleMaintainence = (event) => {
//     event.preventDefault();

//     if (inputValue.toLowerCase() === captchaText.toLowerCase()) {
//       setCaptchaComplete(true);
//       dataArray['Bill'] = form.RegNo
//       // console.log(dataArray['MaintainenceBill'])
//       // navigate('/MaintainenceBillRWP', { replace: true }); 
//       getMaintenanceBill();
//       // window.location.href =
//       //   "https://e-billingbahriatownlahore.com/E_BillingHistory/PrintBill?RegNo=" +
//       //   form.RegNo;
//     } else {
//       setCaptchaComplete(false);
//       setInputValue("");
//       setCaptchaText(generateCaptcha());
//     }
//   };






//   function generateCaptcha() {
//     const characters =
//       "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
//     let result = "";
//     for (let i = 0; i < 6; i++) {
//       result += characters.charAt(
//         Math.floor(Math.random() * characters.length)
//       );
//     }
//     return result;
//   }







//   return (
//     <Container fluid="true">
//       <Row>
//         <Col>
//           <Row>
//             <Tabs
//               id="controlled-tab-example"
//               activeKey={key}
//               onSelect={(k) => setKey(k)}
//               className="mx-5"
//             >
//               <Tab eventKey="home" title="Electricity">
//                 <Row className="mx-5 mt-5">
//                   <Col>
                    // <Form onSubmit={handleSubmit}>
                    //   <Form.Group>
                    //     <Form.Label>kuickpay Reference Number: </Form.Label>
                    //     <Form.Control
                    //       name="RegNo"
                    //       type="number"
                    //       placeholder="KuickPay Refrence No"
                    //       onChange={handleInput}
                    //       value={form.RegNo}
                    //       required
                    //     />
                    //   </Form.Group>

                    //   <label htmlFor="captchaInput">
                    //     Enter the characters you see below:
                    //   </label>
                    //   <br />
                    //   <label
                    //     style={{
                    //       color: "blueviolet",
                    //       fontSize: "30PX",
                    //       fontWeight: "bold",
                    //       marginBottom: "10px",
                    //       fontFamily: "Arial, sans-serif",
                    //       letterSpacing: "2px",
                    //       fontStyle: "italic",
                    //       userSelect: "none",
                    //     }}
                    //   >
                    //     {captchaText}
                    //   </label>
                    //   <br />
                    //   {onCaptchaComplete === false && (
                    //     <p style={{ color: "red" }}>
                    //       Incorrect CAPTCHA. Please try again.
                    //     </p>
                    //   )}

                    //   <Form.Control
                    //     name="captchaInput"
                    //     type="text"
                    //     id="captchaInput"
                    //     placeholder="Enter Captcha"
                    //     onChange={handleInputChange}
                    //     value={inputValue}
                    //     required
                    //   />
                    //   <br />
                    //   <Button type="submit" style={{ marginRight: '20px' }}>Download Bill</Button>
                    //   {
                    //     loading && <Spin size="large" tip={"Fetching Bill"} />
                    //   }

                    // </Form>
//                   </Col>


//                 </Row>
//               </Tab>

//               <Tab eventKey="profile" title="Maintenance">
//                 <Row className="mx-5 mt-5">
//                   <Col>
//                     <Form onSubmit={handleMaintainence}>
//                       <Form.Group>
//                         <Form.Label>Kuickpay Refrence Number: </Form.Label>
//                         <Form.Control
//                           name="RegNo"
//                           type="number"
//                           placeholder="KuickPay Refrence No"
//                           onChange={handleInput}
//                           value={form.RegNo}
//                           required
//                         />
//                       </Form.Group>

//                       <label htmlFor="captchaInput">
//                         Enter the characters you see below:
//                       </label>
//                       <br />
//                       <label
//                         style={{
//                           color: "blueviolet",
//                           fontSize: "30PX",
//                           fontWeight: "bold",
//                           marginBottom: "10px",
//                           fontFamily: "Arial, sans-serif",
//                           letterSpacing: "2px",
//                           fontStyle: "italic",
//                           userSelect: "none",
//                         }}
//                       >
//                         {captchaText}
//                       </label>
//                       <br />
//                       {onCaptchaComplete === false && (
//                         <p style={{ color: "red" }}>
//                           Incorrect CAPTCHA. Please try again.
//                         </p>
//                       )}

//                       <Form.Control
//                         name="captchaInput"
//                         type="text"
//                         id="captchaInput"
//                         placeholder="Enter Captcha"
//                         onChange={handleInputChange}
//                         value={inputValue}
//                         required
//                       />
//                       <br />
//                       <Button type="submit" style={{ marginRight: '20px' }}>Download Bill</Button>
//                       {
//                         loading && <Spin size="large" tip={"Fetching Bill"} />
//                       }
//                     </Form>
//                   </Col>


//                 </Row>
//               </Tab>

//               {/*  */}
//             </Tabs>
//           </Row>
        // </Col>{" "}
        // <Col sm={6}>
        //   <Card
        //     className="mx-3 mt-5"
        //     style={{
        //       width: "80%",
        //       height: "50%",
        //       border: "0px",
        //       backgroundColor: "transparent",
        //     }}
        //   >
        //     <Card.Body style={{ alignContent: "center" }}>
        //       <Card.Img
        //         className="img-fluid w-100"
        //         variant="top"
        //         src={bill02}
        //       />
        //     </Card.Body>
        //   </Card>
        // </Col>
//       </Row>

//       <PageII />
//     </Container>
//   );
// }

// export default SliderMain;




































import React, { useState, useEffect } from "react";
import bill02 from "./bill04.png";
import billtest from "./billtest.jpg";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { Container } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Card from "react-bootstrap/Card";
import Projects from "../Bills/Projects";
import PageII from "../pages/PageII";
import dataArray from './Util'
import { useNavigate } from 'react-router-dom';
import axios from "axios";

import { notification, Spin } from 'antd';



function SliderMain() {
  const [key, setKey] = useState("home");
  const [captchaValue, setCaptchaValue] = useState("");
  const handleCaptchaComplete = (isValid) => {
    console.log("CAPTCHA is valid:", isValid);
  };
  const handleCaptchaChange = (value) => {
    setCaptchaValue(value);
  };

  const [inputValue, setInputValue] = useState("");
  
  const [inputValue1, setInputValue1] = useState("");
  const [captchaText, setCaptchaText] = useState(generateCaptcha());
  const [onCaptchaComplete, setCaptchaComplete] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [form, setForm] = useState({
    RegNo: "",
    RegNo1:"",
  });


  const resetForm = () => {
    setForm(prevForm => ({ ...prevForm, RegNo: "" }));
    setInputValue(""); 
    setCaptchaText(generateCaptcha()); 
  };


  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };  

  const handleInputChange1 = (event) => {
    setInputValue1(event.target.value);
  };

  const handleInput = (e) => {
    const { name, value } = e.target;
    setForm({ ...form, [name]: value });
  };


  
  useEffect(() => {
  }, [form]);

  const getBill = async () => {
    setLoading(true);
    
    let apiUrl = "";
    
    if (dataArray["Bill"].startsWith("01000")) {
      apiUrl = "https://rwp.e-billingbahriatownlahore.com/api/Customer/GetElectrcityBill?ParNewRefrence=";
    } else if (dataArray["Bill"].startsWith("01060")) {
      apiUrl = "https://rwp.e-billingbahriatownlahore.com/api/Customer/BahriaBillingElectricity78?ParNewRefrence=";
    } else if (dataArray["Bill"].startsWith("01010")) {
      apiUrl = "https://rwp.e-billingbahriatownlahore.com/api/Customer/GetMaintenanceBill?ParNewRefrence=";
    } else if (dataArray["Bill"].startsWith("01070")) {
      apiUrl = "https://rwp.e-billingbahriatownlahore.com/api/Customer/bahriabillingmentMaintenance78?ParNewRefrence=";
    }
    
    if (apiUrl === "") {
      // Invalid bill format
      notification.error({
        message: 'Error',
        description: 'Invalid bill format.',
        duration: 5,
      });
      setLoading(false);
      return;
    }
  
    axios.get(
      apiUrl + dataArray["Bill"],
      { responseType: 'blob' }
    )
    .then(response => {
      if (response.status === 200) {
        const pdfBlob = new Blob([response.data], { type: 'application/pdf' });
        const pdfUrl = URL.createObjectURL(pdfBlob);
        window.open(pdfUrl, '_blank');
        resetForm();
        dataArray['Bill'] = ""
        setInputValue(""); 
        setCaptchaText(generateCaptcha()); 
      }
    })
    .catch(error => {
      if (error.response && error.response.status === 404) {
        notification.error({
          message: 'Error',
          description: 'The requested bill was not found. Please enter the correct RegNo.',
          duration: 5,
        });
      }
      console.error('There was an error downloading the PDF:', error);
    })
    .finally(() => {
      setLoading(false);
    });
  };
  
  





  const getMaintenanceBill = async () => {
    setLoading(true); 
    axios.get(
      "https://rwp.e-billingbahriatownlahore.com/api/Customer/GetMaintenanceBill?ParNewRefrence=" + dataArray['Bill'],
      { responseType: 'blob' }
    )
      .then(response => {
        if (response.status === 200) {
          const contentType = response.headers['content-type'];
          if (contentType && contentType.toLowerCase().includes('application/pdf')) {
            const pdfBlob = new Blob([response.data], { type: 'application/pdf' });
            const pdfUrl = URL.createObjectURL(pdfBlob);
            window.open(pdfUrl, '_blank');
            resetForm();
            setCaptchaText(generateCaptcha()); 
          }
        } 
      })
      .catch(error => {
        if (error.response && error.response.status === 404) {
          notification.error({
            message: 'Error',
            description: 'The requested bill was not found. Please enter the correct RegNo.',
            duration: 5,
          });
        }
        console.error('There was an error downloading the PDF:', error);
      })
      .finally(() => {
        setLoading(false); 
      });
  };
  





  const handleSubmit = (event) => {
    event.preventDefault();

    if (inputValue.toLowerCase() === captchaText.toLowerCase()) {
      setCaptchaComplete(true);
      dataArray['Bill'] = form.RegNo
      console.log(dataArray['Bill'])
      getBill();
    } else {
      setCaptchaComplete(false);
      // Reset CAPTCHA
      setInputValue("");
      setCaptchaText(generateCaptcha());
    }
  };


  const handlePay = (event) => {
    event.preventDefault();
      dataArray['Bill'] = form.RegNo
      dataArray['PayBill']=form.RegNo1
      console.log(dataArray['Bill'])
      window.location.href = `https://tinyurl.com/KPayN?cn=${dataArray['PayBill']}`;
  };



  const handleMaintainence = (event) => {
    event.preventDefault();
    if (inputValue.toLowerCase() === captchaText.toLowerCase()) {
      setCaptchaComplete(true);
      dataArray['Bill'] = form.RegNo 
      getMaintenanceBill();
    } else {
      setCaptchaComplete(false);
      setInputValue("");
      setCaptchaText(generateCaptcha());
    }
  };






  function generateCaptcha() {
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let result = "";
    for (let i = 0; i < 6; i++) {
      result += characters.charAt(
        Math.floor(Math.random() * characters.length)
      );
    }
    return result;
  }







  return (
  <Container fluid="true">
  <Row className="mx-5 mt-5">
    <Col sm={3} >
      <Card >
        <Card.Header style={{ backgroundColor:'#063970' , height:'32px'}}>
          <h5 style={{ color:'white', textAlign:'center' }}>Online Bill Print</h5>
        </Card.Header>
        <Card.Body>
          <Form onSubmit={handleSubmit}>
            <Form.Group>
              <Form.Label >kuickpay Reference No:</Form.Label>
              <Form.Control
                name="RegNo"
                type="number"
                placeholder="KuickPay Reference No"
                onChange={handleInput}
                value={form.RegNo}
                required
              />
            </Form.Group>

            <label htmlFor="captchaInput">Enter Captcha:</label>
            <br />
            <label
              style={{
                color: "blueviolet",
                fontSize: "30px",
                fontWeight: "bold",
                marginBottom: "10px",
                fontFamily: "Arial, sans-serif",
                letterSpacing: "2px",
                fontStyle: "italic",
               
              }}
            >
              {captchaText}
            </label>
            <br />
            {onCaptchaComplete === false && (
              <p style={{ color: "red" }}>
                Incorrect CAPTCHA. Please try again.
              </p>
            )}

            <Form.Control
              name="captchaInput"
              type="text"
              id="captchaInput"
              placeholder="Enter Captcha"
              onChange={handleInputChange}
              value={inputValue}
              required
            />
            <br />
            <Button type="submit" style={{ marginRight: '20px', float:'right' }}>Download Bill</Button>
            {loading && <Spin size="large" tip="Fetching Bill" />}
          </Form>
        </Card.Body>
      </Card>
    </Col>
    <Col sm={1} className="d-flex align-items-center justify-content-center">
          <hr style={{ height: '100%', width: '2px', backgroundColor: 'black' }} />
        </Col>
    {/* Second form column, identical to the first */}
    <Col sm={3}>
      <Card >
        <Card.Header style={{ backgroundColor:'#063970', height:'32px' }}>
          <h5 style={{ color:'white' }}>Kuick Pay Online Payment</h5>
        </Card.Header>
        <Card.Body>
          <Form onSubmit={handlePay}>
            <Form.Group>
              <Form.Label>kuickpay Reference No:</Form.Label>
              <Form.Control
                name="RegNo1"
                type="number"
                placeholder="KuickPay Reference No"
                onChange={handleInput}
                value={form.RegNo1}
                required
              />
            </Form.Group>
            <br />
            <Button type="submit" style={{ marginRight: '20px', float:'right' }}>Pay Bill</Button>
          </Form>
        </Card.Body>
      </Card>
    </Col>



  
        <Col sm={5}>
          <Card
            className="mx-3 mt-5"
            style={{
              width: "80%",
              height: "50%",
              border: "0px",
              backgroundColor: "transparent",
            }}
          >
            <Card.Body style={{ alignContent: "center" }}>
              <Card.Img
                className="img-fluid w-100"
                variant="top"
                src={bill02}
              />
            </Card.Body>
          </Card>
        </Col>
  </Row>


  <br></br>
  <br></br>
      <PageII />
    </Container>
    
  );
}

export default SliderMain;
