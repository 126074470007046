
import React, { useEffect, useState, useRef } from "react";
import "./Services.css";
import "./Bill.css";
import axios from "axios";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { saveAs } from "file-saver";
import logo from "../Bills/logo.png";
import dataArray from "./Util";
import bgimage from '../Bills/stamp e.jpg'
import JsBarcode from 'jsbarcode';
import { notification , Spin} from 'antd';


const tableStyle = {
  width: "100%",
  borderCollapse: "collapse",
};

const thStyle = {
  backgroundColor: "Black",
  padding: "3px",
  textAlign: "center",
};

const tdStyle = {
  border: "1px solid gray",
  padding: "5px",
  textAlign: "center",
};




const ServicesBill = () => {


  function formatDateToDDMMYY(dateString) {
    const months = [
      'January', 'February', 'March', 'April', 'May', 'June',
      'July', 'August', 'September', 'October', 'November', 'December'
    ];

    const date = new Date(dateString);
    const day = date.getDate();
    const monthName = months[date.getMonth()];
    const year = date.getFullYear().toString();

    return `${day} ${monthName} ${year}`;
  }




  const [billData, setBillData] = useState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [dataArray1, setDataArray1] = useState([]);
  const barcodeRef = useRef(null);
  useEffect(() => {

    setDataArray1(dataArray["Bill"]);
    const regNo = dataArray["Bill"];
   

    if (dataArray["Bill"]) {
      getBill();
      const handleWheelEvent = (event) => {
        if (event.ctrlKey) {
          event.preventDefault();
        }



      };


      window.addEventListener('wheel', handleWheelEvent, { passive: false });


      return () => {
        window.removeEventListener('wheel', handleWheelEvent);
      };
    }
  }, [dataArray]);

 

  const saveAsImage = () => {
    const billContainer = document.getElementById("bill-container");
    html2canvas(billContainer).then(function (canvas) {
      canvas.toBlob(function (blob) {
        saveAs(blob, "bill.png"); // Change the filename and extension as needed
      });
    });
  };




  const getBill = async () => {
    try {
      const response = await axios.get(
        "https://kuickpay.bsite.net/API/RwpElectricityBill/" + dataArray["Bill"]
        
      );
      setBillData(response.data);
      ;
      //
      console.log("Bill Data:", response.data);
      setLoading(false);
      const barcodeData = `${billData.newRefrence}${billData.plot_Number}`;
      console.log(barcodeData)
      JsBarcode(barcodeRef.current, barcodeData, {
        format: "CODE128", // You can choose other formats like EAN, CODE39, etc.
        lineColor: "#000",
        width: 2,
        height: 30,
        displayValue: true
      });


    } catch (error) {
      console.log("API Error:", error);

      if (error.response.status === 404) {
        // Check if the status code is 404 (Not Found)
        setLoading(false);
        // alert("Not Found: Please enter a valid reference number.");
        setError("Refrenece Number Not Found Please enter a valid Reference Number.");


      } else {
        setError("Something went wrong.");
      }

      setLoading(false);
    }
  };



  const generateBillImage = async () => {
    const billContainer = document.getElementById("bill-container");
    const canvas = await html2canvas(billContainer, { scale: 4 })
    return canvas.toDataURL("image/png");
  };



  const convertImageToPDF = async () => {
    const loadingNotification = notification.open({
      message: 'Generating PDF',
      description: <Spin size="large" />,
      duration: 5, // Duration 0 means it won't auto-close
    });

    const imageDataURL = await generateBillImage();
    const pdf = new jsPDF({
      orientation: "p",
      unit: "pt",
      format: "a4",
      compress: true
    });
    
    const imgProps = pdf.getImageProperties(imageDataURL);
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width +250;
    pdf.addImage(imageDataURL, "PNG", 0, 0, pdfWidth, pdfHeight);
    pdf.save(billData.newRefrence+"ebill.pdf");
    // Create a Blob from the PDF
    // const pdfBlob = pdf.output("blob");
    // const pdfUrl = URL.createObjectURL(pdfBlob);
    // const pdfWindow = pdfUrl;
    // pdfWindow.print();
  };
   

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }
  return (

    <>
      <div style={{ textAlign: "center", marginTop: "20px" }}>
        <button onClick={convertImageToPDF}>Download Bill</button>
      </div>
      <div className="bill-container" id="bill-container" style={{
        backgroundImage: { bgimage },
        backgroundRepeat: 'no-repeat',
        backgroundPosition: '50% 60%',
        backgroundSize: '500px 300px',
        marginTop:'60px'
      }}>
        <div className="bill-history" style={{ height: "70px" }}>
          <div style={{ width: "15%", height: "100px" }}>
            <img src={logo} style={{ width: "70%", height: "70px" }} />
          </div>
          <h3 style={{ textAlign: "center" }}>
            Bahria Town Rawalpindi - Electricity Bill
            <br /> <h5>http://www.bahriatownservices.com.pk/</h5>
          </h3>
        </div>
        <div>
          <table className="bill-table" >
            <tbody>
              <tr>
                {/* <td rowSpan="5" className="logo-cell">
              <img src="path_to_your_logo_image.png" alt="Logo" />
            </td> */}
                <td style={{ fontSize: '9px' }}>Kuick Pay Refrence</td>
                <td style={{ fontWeight: 'bold', fontSize: '9px' }}>Billing Month</td>
                <td style={{ fontWeight: 'bold', fontSize: '9px' }}>ISSUEDATE</td>
                <td style={{ fontWeight: 'bold', fontSize: '9px' }}>Reading Date</td>
                <td style={{ fontWeight: 'bold', fontSize: '9px' }}>Due Date</td>
              </tr>
              <tr>
                <td>{billData?.newRefrence}</td>
                <td>{billData?.billingMonth} </td>
                <td>{formatDateToDDMMYY(billData?.issueDate)}</td>
                <td>{formatDateToDDMMYY(billData?.readingDate)}</td>
                <td>{formatDateToDDMMYY(billData?.dateDue)}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div>
          <div style={{ width: "100%", display: "flex" }}>
            <table
              className="bill-tabletwo"
              style={{
                // marginTop: "-14.5px",
                width: "65%",
                height: "100px",
              }}

            >
              <tbody>
                <tr >
                  <th style={{ width: '100px' }}>Reference</th>
                  <th style={{ width: '100px' }}>TARIFF</th>
                  <th >LOAD</th>
                  <th style={{ width: '100px' }}>METER TYPE</th>
                  <th style={{ textAlign: 'center' }}>Banks</th>
                </tr>
                <tr>
                  <td>{billData?.newRefrence}</td>
                  <td>{billData?.tarrif}</td>
                  <td>{billData?.load}</td>
                  <td>{billData?.plotStatus}</td>

                  <td>
                    KuickPay Refrence No {billData?.newRefrence} Payment Through All
                    Banks Counters Using 1Link OTC Easypaisa / Jazzcash / All
                    Banking Apps Using KuickPay Payment Module All over Pakistan
                    Without Any Charges Bills are Printable and Payable by
                    Credit/Debit Card Using KuickPay Payment Module from our
                    website.
                  </td>
                </tr>
              </tbody>
            </table>
            <table
              className="bill-table"
              style={{ width: "35%", height: '330px', marginTop: '-12px' }}
            >
              <tbody>
                <tr>
                  <th>Month</th>
                  <th style={{ border: "1px solid gray", textAlign: 'Center', fontSize: '9px' }}>UNITS</th>
                  <th style={{ border: "1px solid gray", textAlign: 'Center', fontSize: '9px' }}>BILL</th>
                  <th style={{ border: "1px solid gray", textAlign: 'Center', fontSize: '9px' }}>PAYMENT</th>
                </tr>
                <tr>
                  <td style={{ border: "1px solid gray", fontSize: '8px', fontWeight: 'Thin' }}>Jan{billData?.janYear}</td>
                  <td style={{ border: "1px solid gray" }}>{billData?.janUnits}</td>
                  <td style={{ border: "1px solid gray", fontSize: '8px', fontWeight: 'Thin' }}>{billData?.jan}</td>
                  <td style={{ border: "1px solid gray" }}>{billData?.janStatus}</td>
                </tr>
                <tr>
                  <td style={{ border: "1px solid gray", fontSize: '8px', fontWeight: 'Thin' }}>Feb{billData?.febYear}</td>
                  <td style={{ border: "1px solid gray" }}>{billData?.febUnits}</td>
                  <td style={{ border: "1px solid gray", fontSize: '8px', fontWeight: 'Thin' }}>{billData?.feb}</td>
                  <td style={{ border: "1px solid gray" }}>{billData?.febStatus}</td>
                </tr>

                <tr>
                  <td style={{ border: "1px solid gray", fontSize: '8px', fontWeight: 'Thin' }}>March{billData?.marYear}</td>
                  <td style={{ border: "1px solid gray" }}>{billData?.marUnits}</td>
                  <td style={{ border: "1px solid gray", fontSize: '8px', fontWeight: 'Thin' }}>{billData?.mar}</td>
                  <td style={{ border: "1px solid gray" }}>{billData?.marStatus}</td>
                </tr>

                <tr>
                  <td style={{ border: "1px solid gray", fontSize: '8px', fontWeight: 'Thin' }}>April{billData?.aprilyear}</td>
                  <td style={{ border: "1px solid gray" }}>{billData?.aprilunits}</td>
                  <td style={{ border: "1px solid gray", fontSize: '8px', fontWeight: 'Thin' }}>{billData?.april}</td>
                  <td style={{ border: "1px solid gray" }}>{billData?.aprilstatus}</td>
                </tr>

                <tr>
                  <td style={{ border: "1px solid gray", fontSize: '8px', fontWeight: 'Thin' }}>May{billData?.mayYear}</td>
                  <td style={{ border: "1px solid gray" }}>{billData?.mayUnits}</td>
                  <td style={{ border: "1px solid gray", fontSize: '8px', fontWeight: 'Thin' }}>{billData?.may}</td>
                  <td style={{ border: "1px solid gray" }}>{billData?.mayStatus}</td>
                </tr>

                <tr>
                  <td style={{ border: "1px solid gray", fontSize: '8px', fontWeight: 'Thin' }}>June{billData?.juneYear}</td>
                  <td style={{ border: "1px solid gray" }}>{billData?.juneUnits}</td>
                  <td style={{ border: "1px solid gray", fontSize: '8px', fontWeight: 'Thin' }}>{billData?.june}</td>
                  <td style={{ border: "1px solid gray" }}>{billData?.juneStatus}</td>
                </tr>

                <tr>
                  <td style={{ border: "1px solid gray", fontSize: '8px', fontWeight: 'Thin' }}>July{billData?.julyYear}</td>
                  <td style={{ border: "1px solid gray" }}>{billData?.julyUnits}</td>
                  <td style={{ border: "1px solid gray", fontSize: '8px', fontWeight: 'Thin' }}>{billData?.july}</td>
                  <td style={{ border: "1px solid gray" }}>{billData?.julyUnits}</td>
                </tr>

                <tr>
                  <td style={{ border: "1px solid gray", fontSize: '8px', fontWeight: 'Thin' }}>Aug{billData?.augYear}</td>
                  <td style={{ border: "1px solid gray" }}>{billData?.augUnits}</td>
                  <td style={{ border: "1px solid gray", fontSize: '8px', fontWeight: 'Thin' }}>{billData?.aug}</td>
                  <td style={{ border: "1px solid gray" }}>{billData?.augUnits}</td>
                </tr>
                <tr>
                  <td style={{ border: "1px solid gray", fontSize: '8px', fontWeight: 'Thin' }}>Sep{billData?.sepYear}</td>
                  <td style={{ border: "1px solid gray" }}>{billData?.sepUnits}</td>
                  <td style={{ border: "1px solid gray", fontSize: '8px', fontWeight: 'Thin' }}>{billData?.sep}</td>
                  <td style={{ border: "1px solid gray" }}>{billData?.sepStatus}</td>
                </tr>
                <tr>
                  <td style={{ border: "1px solid gray" }}>Oct{billData?.octYear}</td>
                  <td style={{ border: "1px solid gray", fontSize: '8px', fontWeight: 'Thin' }}>{billData?.octUnits}</td>
                  <td style={{ border: "1px solid gray", fontSize: '8px', fontWeight: 'Thin' }}>{billData?.oct}</td>
                  <td style={{ border: "1px solid gray" }}>{billData?.octStatus}</td>
                </tr>
                <tr>
                  <td style={{ border: "1px solid gray" }}>Nov{billData?.novYear}</td>
                  <td style={{ border: "1px solid gray", fontSize: '8px', fontWeight: 'Thin' }}>{billData?.novUnits}</td>
                  <td style={{ border: "1px solid gray", fontSize: '8px', fontWeight: 'Thin' }}>{billData?.nov}</td>
                  <td style={{ border: "1px solid gray" }}>{billData?.novStatus}</td>
                </tr>

                <tr>
                  <td style={{ border: "1px solid gray", fontSize: '8px', fontWeight: 'Thin' }}>Dec{billData?.decYear}</td>
                  <td style={{ border: "1px solid gray" }}>{billData?.decUnits}</td>
                  <td style={{ border: "1px solid gray", fontSize: '8px', fontWeight: 'Thin' }}>{billData?.dec}</td>
                  <td style={{ border: "1px solid gray" }}>{billData?.decUnits}</td>
                </tr>
                {/* Add rows for other months similarly */}
                {/* ... */}
              </tbody>
            </table>
          </div>
          <div style={{ width: "100%", display: "flex" }}>
            <div style={{ width: "65%", marginTop: "-182px", height: '280px' }}>
              <table style={{ height: '64px' }}>
                <tbody>
                  <tr>
                    <th>NAME & ADDRESS</th>
                    <th>CONN.DATE</th>
                  </tr>
                  <tr>
                    <td style={{ width: "65%", border: '0.5px solid' }}>
                      {billData?.customer_Name} PLOT NO/HOUSE NO {billData?.plot_Number}<br /> STREET {billData?.street_Number}{" "}
                      {" - "}Sector : {billData?.sector}
                    </td>


                    <td>{formatDateToDDMMYY(billData?.conN_DATE)}</td>
                  </tr>
                </tbody>
              </table>

              <table className="meter-table" style={{ marginTop: "-9px" }}>
                <tbody>
                  <tr>
                    <td style={{ border: "1px solid gray", fontWeight: 'bold', fontSize: '9px' }}>Meter No</td>
                    <td style={{ border: "1px solid gray", fontWeight: 'bold', fontSize: '9px' }}>PREVIOUS</td>
                    <td style={{ border: "1px solid gray", fontWeight: 'bold', fontSize: '9px' }}>PRESENT</td>
                    <td style={{ border: "1px solid gray", fontWeight: 'bold', fontSize: '9px' }}>MF</td>
                    <td style={{ border: "1px solid gray", fontWeight: 'bold', fontSize: '9px' }}>UNITS</td>
                    <td style={{ border: "1px solid gray", fontWeight: 'bold', fontSize: '9px' }}>STATUS</td>
                  </tr>

                  <tr>
                    {String(billData?.tarrif) === "A-1b(03T)" ? (
                      <>
                        <td style={{ border: "1px solid gray" }}>{billData?.meterNo}</td>

                        <td style={{ border: "1px solid gray" }}>{billData?.previousPeak}<br />{billData?.previousOffPeak}</td>
                        <td style={{ border: "1px solid gray" }}>{billData?.presentPeak}<br />{billData?.presentOffPeak}</td>


                        <td style={{ border: "1px solid gray" }}>{billData?.mf}</td>

                        <td style={{ border: "1px solid gray" }}>{billData.totalUnit}</td>
                        <td style={{ border: "1px solid gray" }}>{billData.status}</td>
                      </>
                    ) : null}

                    {String(billData?.tarrif) === "A-1b(03)T" ? (
                      <>
                        <td style={{ border: "1px solid gray" }}>{billData?.meterNo}</td>
                        <td style={{ border: "1px solid gray" }}>
                          {billData?.previousPeakSolar}<br />
                          {billData?.previousOffPeakSolar}<br />
                          {billData?.previousExport}
                        </td>
                        <td style={{ border: "1px solid gray" }}>
                          {billData?.currentPeakSolar}<br />
                          {billData?.currentOffPeakSolar}<br />
                          {billData?.currentExport}
                        </td>
                        <td style={{ border: "1px solid gray" }}>{billData?.mf}</td>
                        <td style={{ border: "1px solid gray" }}>{billData?.totalUnit}</td>
                        <td style={{ border: "1px solid gray" }}>{billData?.status}</td>
                      </>
                    ) : (
                      String(billData?.tarrif) !== "A-1b(03T)" && String(billData?.tarrif) !== "A-1b(03)T" && (
                        <>
                          <td style={{ border: "1px solid gray", marginTop: '20px' }}>{billData?.meterNo}</td>
                          <td style={{ border: "1px solid gray" }}>{billData?.previous}</td>
                          <td style={{ border: "1px solid gray" }}>{billData?.present}</td>

                          <td style={{ border: "1px solid gray" }}>{billData?.mf}</td>
                          <td style={{ border: "1px solid gray" }}>{billData?.totalUnit}</td>
                          <td style={{ border: "1px solid gray" }}>{billData?.status}</td>
                        </>
                      )
                    )}

                  </tr>

                  <tr>

                  </tr>
                </tbody>
              </table>

              <table style={{ marginTop: "-10px", height: '100px' }}>
                <tbody>
                  <tr>
                    <td style={{ border: "1px solid gray", fontWeight: 'bold', fontSize: '9px' }}>Total Units Consumed</td>
                    <td style={{ border: "1px solid gray", width: '220px', fontWeight: 'bold', fontSize: '9px' }}>Cost Of Electricity</td>
                    <td style={{ border: "1px solid gray", fontWeight: 'bold', fontSize: '9px' }}>N.J Surcharge</td>

                    <td style={{ border: "1px solid gray", fontWeight: 'bold', fontSize: '9px' }}>Income Tax</td>
                    <td style={{ border: "1px solid gray", fontWeight: 'bold', fontSize: '9px' }}>Advance</td>
                    <td style={{ border: "1px solid gray", fontWeight: 'bold', fontSize: '9px' }}>E.D</td>
                    <td style={{ border: "1px solid gray", fontWeight: 'bold', fontSize: '9px' }}>Installment </td>
                  </tr>
                  <tr>
                    <td style={{ border: "1px solid gray" }}>{billData?.totalUnit}</td>
                    <td style={{ border: "1px solid gray" }}>{billData?.coe}</td>
                    <td style={{ border: "1px solid gray" }}>{billData?.nj}</td>
                    <td style={{ border: "1px solid gray", width: '40px' }}>{billData?.incomeTax}</td>
                    <td style={{ border: "1px solid gray" }}>{billData?.advance}</td>

                    <td style={{ border: "1px solid gray" }}>{billData?.ed}</td>
                    <td style={{ border: "1px solid gray" }}>{0}</td>
                  </tr>
                  <tr>
                    <td style={{ border: "1px solid gray", width: '900px', fontWeight: 'bold', fontSize: '9px' }}>Bill Adj</td>
                    <td style={{ border: "1px solid gray", fontWeight: 'bold', fontSize: '9px' }}>Extra Tax</td>
                    <td style={{ border: "1px solid gray", width: '400px', fontWeight: 'bold', fontSize: '9px' }}>Advance Tax</td>
                    <td style={{ border: "1px solid gray", fontWeight: 'bold', fontSize: '9px' }}>FPA Tax </td>
                    <td style={{ border: "1px solid gray", fontWeight: 'bold', fontSize: '9px' }}>PTV </td>

                    <td style={{ border: "1px solid gray", width: '600px', fontWeight: 'bold', fontSize: '9px' }}>Further Tax</td>
                    <td style={{ border: "1px solid gray", fontWeight: 'bold', fontSize: '9px' }}>GST</td>
                  </tr>
                  <tr>
                    <td style={{ border: "1px solid gray" }}>{billData?.billAdjustment} </td>
                    <td style={{ border: "1px solid gray" }}>{billData?.extraTax} </td>
                    <td style={{ border: "1px solid gray" }}>{billData?.advanceTax}</td>
                    <td style={{ border: "1px solid gray" }}>{billData?.fpa}</td>
                    <td style={{ border: "1px solid gray" }}>{billData?.ptvFees} </td>

                    <td style={{ border: "1px solid gray" }}>{billData?.furthurTax}</td>
                    <td style={{ border: "1px solid gray" }}>{billData?.gst} </td>
                    {/* <td style={{border: "1px solid gray" }}>0</td> */}
                  </tr>

                  <tr>

                    <td style={{ border: "1px solid gray", width: '20%', fontWeight: 'bold', fontSize: '9px' }}>Tariff Adj</td>
                    <td style={{ border: "1px solid gray", width: '20%', fontWeight: 'bold', fontSize: '9px' }}>Fc Surcharge</td>
                    <td style={{ border: "1px solid gray", width: '20%', fontWeight: 'bold', fontSize: '9px' }}>Qtr Adj </td>

                    <td style={{ border: "1px solid gray", width: '40%', fontWeight: 'bold', fontSize: '9px' }}>Bill  Calculation</td>
                  </tr>

                  <tr>

                    <td style={{ border: "1px solid gray" }}>{billData?.tarrifadj}</td>
                    <td style={{ border: "1px solid gray" }}>{billData?.fcSurcharge}</td>
                    <td style={{ border: "1px solid gray" }}>{billData?.qtr}</td>
                    {String(billData?.tarrif) === "A-1b(03T)" ? (
                      <td style={{ border: "1px solid gray", width: '620px' }}>
                        {billData?.unitsRate1} * {billData?.appliedUnitRate1} <br />
                        {billData?.unitsRate2} * {billData?.appliedUnitRate2}
                      </td>
                    ) : <td style={{ border: "1px solid gray" }}>
                      {billData?.unitsRate1} * {billData?.appliedUnitRate1}
                    </td>}



                    {String(billData?.tarrif) === "A-1b(03)T" ? (
                      <td style={{ border: "1px solid gray", width: '620px' }}>
                        {billData?.unitsRate1} * {billData?.appliedUnitRate1} <br />
                        {billData?.unitsRate2} * {billData?.appliedUnitRate2}
                      </td>
                    ) : (
                      String(billData?.tarrif) !== "A-1b(03T)" && String(billData?.tarrif) !== "A-1b(03)" && (
                        <td style={{ border: "1px solid gray" }}>
                          {billData?.unitsRate1} * {billData?.appliedUnitRate1}
                        </td>
                      )
                    )}



                  </tr>
                </tbody>
              </table>
            </div>

            <div style={{ width: "35%", marginTop: '-12px', height: '90.9px', border: '0.5px solid' }}>
              <table >
                <tbody >
                  <tr>
                    <td style={{ fontWeight: 'bold' }}>Current Bill</td>
                    <td >{billData?.currentBill}</td>
                  </tr>
                  <tr>
                    <td style={{ fontWeight: 'bold' }}>Arrears</td>
                    <td >{billData?.arrears}</td>
                  </tr>
                  <tr>
                    <td style={{ fontWeight: 'bold' }}></td>
                    <td ></td>
                  </tr>
                  <tr>
                    <td style={{ fontWeight: 'bold' }}>Payment within duedate</td>
                    <td >{billData?.amountDueDate}</td>
                  </tr>
                  <tr>
                    <td style={{ fontWeight: 'bold' }}>LP.Surcharge</td>
                    <td>{billData?.surcharges}</td>
                  </tr>
                  <tr>
                    <td style={{ fontWeight: 'bold' }}>Payment After Due Date</td>
                    <td >{billData?.amountAfterDate}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>


        <div style={{ width: "100%", display: "flex", marginTop: String(billData?.tarrif) === "A-1b(03T)" ? "-19px" : String(billData?.tarrif) === "A-1b(03)T" ? "-5px" : "-34px" }}>
          <table style={{ border: '0.5px solid gray', width: '50%' }}>
            <tbody>
              <tr style={{ textAlign: 'center', fontWeight: 'bold' }}>
                Instructions
              </tr>
              <tr style={{ border: "1px solid gray", fontSize: '11px' }} >* Incase of disconnection restration only on payment of fees as under:</tr>
              <tr>
                <td style={{ border: "1px solid gray", fontWeight: 'bold' }}>Arrears Amount</td>
                <td style={{ border: "1px solid gray", fontWeight: 'bold' }}>Charges</td>
              </tr>
              <tr>
                <td style={{ border: "1px solid gray", fontWeight: 'bold' }}>up to 1000</td>
                <td style={{ border: "1px solid gray" }}>Rs.100/-</td>
              </tr>
              <tr>
                <td style={{ border: "1px solid gray", fontWeight: 'bold' }}>Btw 1,001 to 5,000</td>
                <td style={{ border: "1px solid gray" }}>Rs.300/-</td>
              </tr>
              <tr>
                <td style={{ border: "1px solid gray", fontWeight: 'bold' }}>Btw 5,001 to 15,000</td>
                <td style={{ border: "1px solid gray" }}>Rs.900/-</td>
              </tr>

            </tbody>
          </table>


          <table style={{ border: '0.5px solid', width: '50%'}}>
            <tbody>

              <tr>
                <td style={{ border: "0.5px solid gray" }}>* To Pay Electric Bill through KUICKPAY Use : {billData?.newRefrence}</td>

              </tr>
              <tr><td style={{ border: "0.5px solid gray" }}>* For Electricity Bills compalints Phase 1 to 6 dial.5733277</td></tr>
              <tr>
                <td style={{ border: "0.5px solid gray" }}>* For Electricity Bills compalints Phase 7 to 8 dial.5410387</td>

              </tr>
              <tr><td style={{ border: "0.5px solid gray" }}>* For Complaints Safari I Compalint office:5706990,5509277</td></tr>

            </tbody>
          </table>
        </div>

        <div className="section" style={{ marginTop: "-10px" }}>
          <table style={tableStyle}>
            <tr>
              <td style={{ fontWeight: "bold", border: "1px solid gray", textAlign: 'center' }}>Customer Name</td>
              <td
                style={{
                  fontWeight: "bold",
                  colspan: "4",
                  border: "1px solid gray",
                  textAlign: 'center'
                }}
              >
                Bahria Town Rawalpindi - Electricity Bill(Office Copy)
              </td>
              <td style={{ fontWeight: 'bold', borderLeft: '1px solid gray', borderBottom: '1px solid gray', borderRight: '1px solid gray' }}>KuickPay Refrence Number</td>
              <td style={{ borderLeft: '1px solid gray', borderBottom: '1px solid gray', borderRight: '1px solid gray' }} colspan="2">
                {billData?.newRefrence}
              </td>
            </tr>

            <tr>
              <td style={{ borderLeft: '1px solid gray', borderBottom: '1px solid gray', borderRight: '1px solid gray' }}>{billData?.customer_Name} </td>
              <td style={{ borderLeft: '1px solid gray', borderBottom: '1px solid gray', borderRight: '1px solid gray' }}>
                PLOT NO/HOUSE NO {billData?.plot_Number}{" "}
              </td>
              <td style={{ fontWeight: 'bold', borderLeft: '1px solid gray', borderBottom: '1px solid gray', borderRight: '1px solid gray' }}>Meter Number</td>
              <td style={{ borderLeft: '1px solid gray', borderBottom: '1px solid gray', borderRight: '1px solid gray' }} colspan="2">
                {billData?.meterNo}
              </td>
            </tr>

            <tr>
              <td style={{ fontWeight: 'bold', borderLeft: '1px solid gray', borderBottom: '1px solid gray', borderRight: '1px solid gray' }}>Bill Month</td>
              <td style={{ fontWeight: 'bold', borderLeft: '1px solid gray', borderBottom: '1px solid gray', borderRight: '1px solid gray' }}>Due Date</td>

              <td style={{ fontWeight: 'bold', borderLeft: '1px solid gray', borderBottom: '1px solid gray', borderRight: '1px solid gray' }}>Payment Within due date</td>
              <td style={{ fontWeight: 'bold', borderLeft: '1px solid gray', borderBottom: '1px solid gray', borderRight: '1px solid gray' }}>Payment after Due Date</td>
            </tr>
            <tr>
              <td style={{ borderLeft: '1px solid gray', borderBottom: '1px solid gray', borderRight: '1px solid gray' }}>{billData?.billingMonth}  2023</td>
              <td style={{ borderLeft: '1px solid gray', borderBottom: '1px solid gray', borderRight: '1px solid gray' }}>{formatDateToDDMMYY(billData?.dateDue)}</td>
              <td style={{ borderLeft: '1px solid gray', borderBottom: '1px solid gray', borderRight: '1px solid gray' }}>{billData?.amountDueDate}</td>
              <td style={{ borderLeft: '1px solid gray', borderBottom: '1px solid gray', borderRight: '1px solid gray' }}>{billData?.amountAfterDate}</td>
            </tr>

            <tr>
              <td colspan="5" style={{ fontWeight: 'bold', borderLeft: '1px solid gray', borderRight: '1px solid gray' }}>
                --------------------------------------------------------------------------
                CUT HERE
                -----------------------------------------------------------------------------
              </td>
            </tr>

            <tr>
              <td style={{ fontWeight: "bold", border: "1px solid gray", textAlign: 'center' }}>Customer Name</td>
              <td
                style={{
                  fontWeight: "bold",
                  colspan: "4",
                  border: "1px solid gray",
                  textAlign: 'center'
                }}
              >
                Bahria Town Rawalpindi - Electricity Bill(Bank Copy)
              </td>
              <td style={{ fontWeight: 'bold', borderLeft: '1px solid gray', borderBottom: '1px solid gray', borderRight: '1px solid gray', borderTop: '1 px solid gray', borderTop: '1px solid gray' }}>KuickPay Refrence Number</td>
              <td style={{ borderLeft: '1px solid gray', borderBottom: '1px solid gray', borderRight: '1px solid gray', borderTop: '1px solid gray' }} colspan="2">
                {billData?.newRefrence}
              </td>
            </tr>
            <tr>
              <td style={{ borderLeft: '1px solid gray', borderBottom: '1px solid gray', borderRight: '1px solid gray' }}>{billData?.customer_Name} </td>
              <td style={{ borderLeft: '1px solid gray', borderBottom: '1px solid gray', borderRight: '1px solid gray' }}>
                PLOT NO/HOUSE NO {billData?.plot_Number}{" "}{" "}
              </td>
              <td style={{ fontWeight: 'bold', borderLeft: '1px solid gray', borderBottom: '1px solid gray', borderRight: '1px solid gray' }}>Meter Number</td>
              <td style={{ borderLeft: '1px solid gray', borderBottom: '1px solid gray', borderRight: '1px solid gray' }} colspan="2">
                {billData?.meterNo}
              </td>
            </tr>

            <tr>
              <td style={{ fontWeight: 'bold', borderLeft: '1px solid gray', borderBottom: '1px solid gray', borderRight: '1px solid gray' }}>Bill Month</td>
              <td style={{ fontWeight: 'bold', borderLeft: '1px solid gray', borderBottom: '1px solid gray', borderRight: '1px solid gray' }}>Due Date</td>

              <td style={{ fontWeight: 'bold', borderLeft: '1px solid gray', borderBottom: '1px solid gray', borderRight: '1px solid gray' }}>Payment Within due date</td>
              <td style={{ fontWeight: 'bold', borderLeft: '1px solid gray', borderBottom: '1px solid gray', borderRight: '1px solid gray' }}>Payment after Due Date</td>
            </tr>
            <tr>
              <td style={{ borderLeft: '1px solid gray', borderBottom: '1px solid gray', borderRight: '1px solid gray' }}> {billData?.billingMonth} 2023</td>
              <td style={{ borderLeft: '1px solid gray', borderBottom: '1px solid gray', borderRight: '1px solid gray' }}>{formatDateToDDMMYY(billData?.dateDue)}</td>

              <td style={{ borderLeft: '1px solid gray', borderBottom: '1px solid gray', borderRight: '1px solid gray' }}>{billData?.amountDueDate}</td>
              <td style={{ borderLeft: '1px solid gray', borderBottom: '1px solid gray', borderRight: '1px solid gray' }}>{billData?.amountAfterDate}</td>
            </tr>
          </table>

          {/* <div style={{textAlign:'center' ,fontFamily:'verdena',fontWeight:'bold'}}>
          <svg ref={barcodeRef}></svg>
          <br/>
          scan Barcode to verify bill
        </div> */}
        </div>
        
      </div>

    </>
  );
};



export default ServicesBill;
