import React from "react";
import { Navbar, Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import logo from "../Bills/logo.png";

function NavScrollExample() {
  return (
    <Navbar expand="lg" style={{ backgroundColor: "#E6E6FA" }}>
      <Container fluid>
        <Navbar.Brand
          href="#"
          style={{ display: "flex", alignItems: "center" }}
        >
          <img
            src={logo}
            width="40"
            height="40"
            className="d-inline-block align-top"
            alt="Logo"
          />
          <span
            style={{
              marginLeft: "10px",
              fontWeight: "bold",
              fontSize: "clamp(0.8rem, 2vw, 1rem)", // Adjusts between 0.8rem and 1rem based on viewport width
              width: "90%",
              overflowWrap: "break-word", // Ensures text wraps to prevent overflow
            }}
          >
            Bahria Town Electricity and Maintenance Services
          </span>
        </Navbar.Brand>
        {/* <Navbar.Toggle aria-controls="responsive-navbar-nav" /> */}
        <Navbar.Collapse id="responsive-navbar-nav">
          {/* If you have navigation links, they can be added here */}
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default NavScrollExample;
