import React from "react";
import image from "./banner02.jpg";
import AnimatedText from "react-animated-text-content";
import Card from "react-bootstrap/Card";
import CardGroup from "react-bootstrap/CardGroup";
import shahid from "./banner02.jpg";
import abbas from "./banner02.jpg";
import bilal from "./banner02.jpg";
import pageii from "./pageii.jpg";
import main01 from "./image0101.jpg";
import main02 from "./image0102.jpg";
import main03 from "./image0103.jpg";
import main04 from "./image0104.jpg";
import main05 from "./main05.png";
import { Container } from "react-bootstrap";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

function App() {
  return (
    <Container fluid="true">
      {/* <div style={{ backgroundImage:`url(${image})`,
    backgroundRepeat:"no-repeat",backgroundSize:"contain", 
    height:600,width:'100%'}}> */}
      <CardGroup style={{backgroundColor: "transparent" }}>
 
   <Row className="mx-auto" > 
      <Col>
     <Card  style={{ backgroundColor: "transparent" }}>
     <Card.Title className="mx-3 mt-3">Karachi  </Card.Title>
          <Card.Img variant="top" className="mx-auto"
          style={{width:"90%", height:"250px", alignItems:"center"}} 
           src={main01} />
        
           {/* <Card.Body>
            {" "}
            Bahria Town has mastered the art of urban and town planning and 
            transformed a great vision into reality. Our world class 
            communities are far ahead of their time. All developments 
            have the most advanced infrastructure and world class facilities 
            in the country.  </Card.Body> */}
        </Card>
        </Col>

        <Col>
     <Card style={{ backgroundColor: "transparent" }}>
     <Card.Title className="mx-3 mt-3">Lahore  </Card.Title>
          <Card.Img variant="top" className="mx-auto"
          style={{width:"90%", height:"250px", alignItems:"center"}} 
          src={main03} />
       
           {/* <Card.Body>
            {" "}
            Bahria Town has mastered the art of urban and town planning and 
            transformed a great vision into reality. Our world class 
            communities are far ahead of their time. All developments 
            have the most advanced infrastructure and world class facilities 
            in the country.  </Card.Body> */}
        </Card>
        </Col>



</Row>

<Row className="mx-auto mt-4">
    <Col>
        <Card style={{ backgroundColor: "transparent" }}>
        <Card.Title className="mx-3 mt-3">Nawabshah  </Card.Title>
          <Card.Img className="mx-auto" variant="top" src={main02} 
          style={{width:"90%", height:"250px", alignItems:"center"}} 
          />
                  {/* <Card.Body>
            {" "}
            Bahria Town has mastered the art of urban and town planning and 
            transformed a great vision into reality. Our world class 
            communities are far ahead of their time. All developments 
            have the most advanced infrastructure and world class facilities 
            in the country.  </Card.Body> */}
        </Card>
      </Col>

      <Col>
        <Card style={{ backgroundColor: "transparent" }}>
        <Card.Title className="mx-3 mt-3">Rawalpindi  </Card.Title>
          <Card.Img className="mx-auto" variant="top" 
          style={{width:"90%", height:"250px", alignItems:"center"}} 
          src={main04} />
         
          {/* <Card.Body>
            {" "}
            Bahria Town has mastered the art of urban and town planning and 
            transformed a great vision into reality. Our world class 
            communities are far ahead of their time. All developments 
            have the most advanced infrastructure and world class facilities 
            in the country.  </Card.Body> */}
        </Card>
      </Col>
    </Row>

      </CardGroup>
    </Container>
  );
}

export default App;
