import React from "react";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import CardGroup from "react-bootstrap/CardGroup";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import EmailIcon from "@mui/icons-material/Email";
import { faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
const mystyle = {
  color: "white",
  backgroundColor: "#063970",
  fontFamily: "Arial",

  a: {
    color: "white",
    fontFamily: "Arial",
    textDecoration: "none",
  },
};

function FooterCurrent() {
  return (
    <Container fluid style={mystyle}>
      <Row className="p-3" style={{ marginTop: "100px" }}></Row>

      <footer className="page-footer font-small white pt-4">
       
      <div className="row">
  <div className="col-md-6 mb-3">
    <h5 className="text-uppercase text-warning">Corporate Offices</h5>
    <ul className="list-unstyled text-info">
      <li>
        <FontAwesomeIcon icon={faMapMarkerAlt} className="mr-2" />
        Corporate Office-I, Park Rd, <br/>Bahria Town Phase II Rawalpindi,
        Punjab, Pakistan
      </li>
      <li>
        <FontAwesomeIcon icon={faMapMarkerAlt} className="mr-2" />
        Corporate Office-II, Park Rd, <br/>Bahria Town Phase II Rawalpindi,
        Punjab, Pakistan
      </li>
    </ul>
  </div>

  <div className="col-md-6">
    <h5 className="text-uppercase text-warning">Contact Us</h5>
    <div className="row">
      <div className="col-md-6">
        <p>
          <span className="text-uppercase text-warning">Billing Office : Phase 1 to 6</span>
          <br />
          Central Electricity Billing Office, Garden Avenue, Near Civic Center, Phase 4 <br />
          Contact No: +92(51) 5733277 <br />
          Email: cbdbt1to6@gmail.com
        </p>
      </div>
      <div className="col-md-6">
        <p>
          <span className="text-uppercase text-warning">Billing Office : Phase 7 to 8</span>
          <br />
          Central Electricity Billing Office, Usman Block, Near Grid Station, Phase 8. <br />
          Contact No: +92 (51) 5410387 & 5410080 <br />
          Email: Cbdbt7to9@gmail.com
        </p>
      </div>
    </div>
  </div>
</div>

        

        <div className="footer-copyright text-center py-3">
          © 2023 Copyright:
          <a
            className="text-decoration-none"
            href="https://commanderschoolingsys.edu.pk/"
          >
            {" "}
            Bahria Town
          </a>
        </div>
      </footer>
    </Container>
  );
}

export default FooterCurrent;
