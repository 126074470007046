
import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "../practise/ProjectCard";
// import Particle from "../Particle";
 import one from "../Bills/banner01.jpg";
// import emotion from "../../Assets/Projects/emotion.png";
// import editor from "../../Assets/Projects/codeEditor.png";
// import chatify from "../../Assets/Projects/chatify.png";
// import suicide from "../../Assets/Projects/suicide.png";
// import bitsOfCode from "../../Assets/Projects/blog.png";

function Projects() {
  return (
    <Container fluid className="project-section">
      {/* <Particle /> */}
      <Container>
      
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
        <Col
            md={4}
            className="project-card-wrapper"
            style={{
              transition: "transform 0.3s ease",
            }}
            onMouseOver={(e) => {
              e.currentTarget.style.transform = "scale(1.05)";
            }}
            onMouseOut={(e) => {
              e.currentTarget.style.transform = "scale(1)";
            }}
          >
            <ProjectCard
              imgPath={one}
              isBlog={false}
              title="BTK"
              description="Bahria Town has mastered the art of urban and town planning and transformed a great vision into reality. Our world class communities are far ahead of their time. All developments have the most advanced infrastructure and world class facilities in the country."
              ghLink="#"
              demoLink="#"
            />
          </Col>
         

          <Col
            md={4}
            className="project-card-wrapper"
            style={{
              transition: "transform 0.3s ease",
            }}
            onMouseOver={(e) => {
              e.currentTarget.style.transform = "scale(1.05)";
            }}
            onMouseOut={(e) => {
              e.currentTarget.style.transform = "scale(1)";
            }}
          >
            <ProjectCard
              imgPath={one}
              isBlog={false}
              title="BTK"
              description="Bahria Town has mastered the art of urban and town planning and transformed a great vision into reality. Our world class communities are far ahead of their time. All developments have the most advanced infrastructure and world class facilities in the country."
              ghLink="#"
              demoLink="#"
            />
          </Col>

          <Col
            md={4}
            className="project-card-wrapper"
            style={{
              transition: "transform 0.3s ease",
            }}
            onMouseOver={(e) => {
              e.currentTarget.style.transform = "scale(1.05)";
            }}
            onMouseOut={(e) => {
              e.currentTarget.style.transform = "scale(1)";
            }}
          >
            <ProjectCard
              imgPath={one}
              isBlog={false}
              title="BTK"
              description="Bahria Town has mastered the art of urban and town planning and transformed a great vision into reality. Our world class communities are far ahead of their time. All developments have the most advanced infrastructure and world class facilities in the country."
              ghLink="#"
              demoLink="#"
            />
          </Col>
          </Row>
              
          <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
            
          <Col
            md={4}
            className="project-card-wrapper"
            style={{
              transition: "transform 0.3s ease",
            }}
            onMouseOver={(e) => {
              e.currentTarget.style.transform = "scale(1.05)";
            }}
            onMouseOut={(e) => {
              e.currentTarget.style.transform = "scale(1)";
            }}
          >
            <ProjectCard
              imgPath={one}
              isBlog={false}
              title="BTK"
              description="Bahria Town has mastered the art of urban and town planning and transformed a great vision into reality. Our world class communities are far ahead of their time. All developments have the most advanced infrastructure and world class facilities in the country."
              ghLink="#"
              demoLink="#"
            />
          </Col>

          <Col
            md={4}
            className="project-card-wrapper"
            style={{
              transition: "transform 0.3s ease",
            }}
            onMouseOver={(e) => {
              e.currentTarget.style.transform = "scale(1.05)";
            }}
            onMouseOut={(e) => {
              e.currentTarget.style.transform = "scale(1)";
            }}
          >
            <ProjectCard
              imgPath={one}
              isBlog={false}
              title="BTK"
              description="Bahria Town has mastered the art of urban and town planning and transformed a great vision into reality. Our world class communities are far ahead of their time. All developments have the most advanced infrastructure and world class facilities in the country."
              ghLink="#"
              demoLink="#"
            />
          </Col>

          <Col
            md={4}
            className="project-card-wrapper"
            style={{
              transition: "transform 0.3s ease",
            }}
            onMouseOver={(e) => {
              e.currentTarget.style.transform = "scale(1.05)";
            }}
            onMouseOut={(e) => {
              e.currentTarget.style.transform = "scale(1)";
            }}
          >
            <ProjectCard
              imgPath={one}
              isBlog={false}
              title="BTK"
              description="Bahria Town has mastered the art of urban and town planning and transformed a great vision into reality. Our world class communities are far ahead of their time. All developments have the most advanced infrastructure and world class facilities in the country."
              ghLink="#"
              demoLink="#"
            />
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Projects;