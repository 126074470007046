import React from "react";
import image from "./banner02.jpg";
import AnimatedText from "react-animated-text-content";
import Card from "react-bootstrap/Card";
import CardGroup from "react-bootstrap/CardGroup";

import { Container } from "react-bootstrap";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import PageII from '../pages/PageII'
function App() {
  return (
    <Container fluid="true">
      {/* <div style={{ backgroundImage:`url(${image})`,
    backgroundRepeat:"no-repeat",backgroundSize:"contain", 
    height:600,width:'100%'}}> */}
      <CardGroup style={{backgroundColor: "transparent" }}>
 

<Row>
  <Card>
    <Card.Body>
    Bahria Town has been shaping landscapes and lives in Pakistan since the company’s inception in 1996. Not just about building homes, Bahria Town develops value-added, master-planned communities housing thousands of families who are enjoying a complete living experience. Upon completion, the under-development projects in Karachi, Islamabad, Rawalpindi, Lahore and Nawabshah will accommodate more than a million residents. Bahria Town’s 35,000 employees are delivering iconic developments, driving leadership, pioneering innovation and creating a legacy for generations to come.

Bahria Town aspires to be the greatest Pakistani real estate developer of all times, with projects offered to an eclectic mix of segments, at choice locations with world-class amenities, while ensuring the highest international standards, timely delivery and lifelong customer satisfaction.
    </Card.Body>
  </Card>
</Row>
<PageII />

   

      </CardGroup>
    </Container>
  );
}

export default App;
