import React, { useEffect, useState } from "react";
import "./Bill.css";
import Image4 from "../Bills/Tax.png";
import image2 from "../pages/image2.png";
import axios from "axios";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { saveAs } from "file-saver";
import logo from "../Bills/logo.png";
import dataArray from "./Util";
import mbg from "../Bills/mbg.jpg"
import { notification , Spin} from 'antd';



const Bill = () => {
  const [billData, setBillData] = useState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [dataArray1, setDataArray1] = useState([]);
  //
  

  const getBill = async () => {
    try {
      const response = await axios.get(
        "https://kuickpay.bsite.net/api/RwpElectricityBill/GetBillByMent/" +
        //"https://localhost:7050/api/RwpElectricityBill/GetBillByMent/"+ 
        dataArray["Bill"]
      );
      setBillData(response.data);
      console.log(billData);
      // console.log("Bill Data:", response.data);
      // console.log("Customer Name:", response.data[0].Customer_Name);
      setLoading(false);
    } catch (error) {
      if (error.response.status === 404) {
        // Handle 404 error
        // alert("Bill not found. Please check the Bill ID.");
        setError("Refrenece Number not found.Please enter a valid Reference Number..");
      } else {
        // Handle other errors
        setError("Something went wrong.");
      }
      setLoading(false);
    }
  };

  useEffect(() => {
    setDataArray1(dataArray["Bill"]);
    console.log("RegNo" + dataArray["Bill"]);
    getBill();
    const handleWheelEvent = (event) => {
      if (event.ctrlKey) {
        event.preventDefault();
      }
    };

    // Add event listener for the wheel event on the window
    window.addEventListener('wheel', handleWheelEvent, { passive: false });

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('wheel', handleWheelEvent);
    };

  }, []); // Empty dependency array to fetch the data only once on component mount

  const saveAsImage = () => {
    const billContainer = document.getElementById("bill-container");
    html2canvas(billContainer).then(function (canvas) {
      canvas.toBlob(function (blob) {
        saveAs(blob, "bill.png"); // Change the filename and extension as needed
      });
    });
  };

  function formatDateToDDMMYY(dateString) {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = String(date.getFullYear()).slice(2);
    return `${day}-${month}-${year}`;
  }

  // const saveAsPDF = () => {
  //   const billContainer = document.getElementById("bill-container");
  //   const PDF_Width = billContainer.offsetWidth;
  //   const PDF_Height = billContainer.offsetHeight;

  //   html2canvas(billContainer, { allowTaint: true }).then(function (canvas) {
  //     const imgData = canvas.toDataURL("image/png");

  //     const pdf = new jsPDF("p", "pt", [PDF_Width, PDF_Height]);
  //     const imageAspectRatio = canvas.width / canvas.height;
  //     const imageWidth = PDF_Width;
  //     const imageHeight = imageWidth / imageAspectRatio;

  //     pdf.addImage(imgData, "PNG", 0, 0, imageWidth, imageHeight);

  //     pdf.save("Bill.pdf");
  //   });
  // };







  const generateBillImage = async () => {
    const billContainer = document.getElementById("bill-containermaint");
    const canvas = await html2canvas(billContainer, { scale: 4 })
    return canvas.toDataURL("image/png");
  };


  const convertImageToPDF = async () => {
    const loadingNotification = notification.open({
      message: 'Generating PDF',
      description: <Spin size="large" />,
      duration: 5, // Duration 0 means it won't auto-close
    });
    const imageDataURL = await generateBillImage();
    const pdf = new jsPDF("p", "pt", "a4");
    const imgProps = pdf.getImageProperties(imageDataURL);
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width +100;
    pdf.addImage(imageDataURL, "PNG", 0, 0, pdfWidth, pdfHeight);

    const pdfBlob = pdf.output("blob");
    const pdfUrl = URL.createObjectURL(pdfBlob);
    const pdfWindow = window.open(pdfUrl);
};
  const tableStyle = {
    width: "100%",
    borderCollapse: "collapse",
  };

  const thStyle = {
    backgroundColor: "Black",
    padding: "3px",
    textAlign: "center",
  };

  const tdStyle = {
    border: "1px solid black",
    padding: "3px",
    textAlign: "center",
    fontWeight:"bold"
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <>
      <div style={{ textAlign: "center", marginTop: "20px" }}>
        <button onClick={convertImageToPDF}>Download Bill in pdf format</button>
      </div>
      <div className="bill-containermaint" id="bill-containermaint"  style={{
    backgroundImage: {mbg},
    backgroundRepeat: 'no-repeat',
    backgroundPosition: '50% 40%',
    backgroundSize: '500px 300px',
  }}>
        <div className="bill-history" style={{ height: "60px" }}>
          <div style={{ width: "25%", height: "90px" }}>
            <img src={logo} style={{ width: "40%", height: "70px" }} />
          </div>
          <h3 style={{ textAlign: "center" }}>
            Bahria Town Rawalpindi - Maintenance Billl
            <br /> <h5>Your Life Style Destination</h5>
          </h3>
        </div>
        <div className="section">
          {/* <div className="section-title" style={{ backgroundColor: 'steelblue', color: 'white', fontSize: '20px' }}>Customer Information</div> */}
          <table style={{ border: "1px solid ", width: "100%" }}>
            <tr>
             
            </tr>
            <tr>
              <td>
                {billData?.customer_Name}
                {" - "} <br />
                House No : {billData?.plot_Number} <br /> Street:{" "}
                {billData?.street_Number} {" - "}Sector : {billData?.sector}
              </td>
              <td>
                <br />{" "}
              </td>

              {/* Add values for additional fields here */}
            </tr>
          </table>

          <div className="section">
            {/* <div className="section-title" style={{ backgroundColor: 'steelblue', color: 'white', fontSize: '20px' }}>Customer Information</div> */}
            <table style={{ border: "1px solid " }}>
              <tr>
                <th style={{ width: "390px" }}>Billing Month</th>

                <th style={{ width: "200px" }}>Issue Date</th>
                <th style={{ width: "200px" }}>Due Date</th>

                <th style={{ width: "200px" }}>Refrence No</th>
              </tr>
              <tr>
                <td>{billData?.billingMonth} </td>

                <td>{formatDateToDDMMYY(billData?.issueDate)}</td>
                <td>{formatDateToDDMMYY(billData?.dateDue)}</td>
                <td>{billData?.newRefrence}</td>
              </tr>
            </table>
          </div>

          <div className="section" style={{ marginTop: "-20px" }}>
            {/* <div className="section-title" style={{ backgroundColor: 'steelblue', color: 'white', fontSize: '20px' }}>Customer Information</div> */}
            <table style={{ border: "1px solid " }}>
              <tr>
                <th style={{ width: "390px" }}>Type</th>

                <th style={{ width: "200px" }}>Size</th>
                <th style={{ width: "200px" }}>Plot Status</th>
                <th style={{ width: "200px" }}>Meter No</th>
                {/* <th style={{ width: "200px" }}>Refrence No</th> */}
              </tr>
              <tr>
                <td>{billData?.plotStatus} </td>

                <td>{billData?.size}</td>
                <td>{billData?.plotStatus}</td>
                <td>{billData?.meterNo}</td>
              </tr>
            </table>
          </div>
        </div>

        <div className="bill-history">
          <div
            className="section"
            style={{ marginTop: "-20px", width: "200%" }}
          >
            <table style={{ border: "1px solid " }}>
              <tr>
                <th style={{ width: "350px", textAlign: "center" }}>Banks</th>
              </tr>
              <tr>
                <td style={{ font: "2px", textAlign: "center" }}>
                  Askari Bank/Bank Alfallah Phase 4
                  <br />
                  Bank Alfallah Islamic Phase 6 With Online PMNT
                  <br />
                  Bank Al Habib Phase 4 Bank Islami Phase 1(Online)
                  <br />
                  Allied BankJS/Soneri Civic Center/Habib Metro/Kuickpay)
                  <br />
                  Silk Bank UBL MCA No.219021382

                </td>
              </tr>
            </table>
          </div>


          <div
            className="bill-his-section"
            style={{ marginTop: "-20px", height: "355px" }}
          >

            <table
              style={{
                border: "1px solid ",
                justifyContent: "center",
                width: "127%",
              }}
            >
              <tr>
                <th style={{ width: "170px", height: "20px" }}>Month</th>
                <th style={{ width: "170px", height: "20px" }}>Amount</th>
                <th style={{ width: "170px", height: "20px" }}>Billed</th>
                <th style={{ width: "200px", height: "20px" }}>Status</th>
              </tr>

              <tr>
                <td style={{fontWeight:"bold"}}>Jan{billData?.janYear}</td>
                <td >{billData?.janmentcharges}</td>
                <td>{billData?.billamountjan}</td>
                <td>{billData?.janStatus}</td>
              </tr>

              <tr>
                <td style={{fontWeight:"bold"}}>Feb{billData?.febYear}</td>
                <td>{billData?.febmentcharges}</td>
                <td>{billData?.billamountfeb}</td>
                <td>{billData?.billamountfeb}</td>
              </tr>

              <tr>
                <td style={{fontWeight:"bold"}}>March{billData?.marYear}</td>
                <td>{billData?.marmentcharges}</td>
                <td>{billData?.billamountmar}</td>
                <td>{billData?.marStatus}</td>
              </tr>

              <tr>
                <td style={{fontWeight:"bold"}}>April{billData?.aprilyear}</td>
                <td>{billData?.aprilmentcharges}</td>
                <td>{billData?.billamountapril}</td>
                <td>{billData?.aprilstatus}</td>
              </tr>

              <tr>
                <td style={{fontWeight:"bold"}}>May{billData?.mayYear}</td>
                <td>{billData?.maymentcharges}</td>
                <td>{billData?.billamountmay}</td>
                <td>{billData?.mayStatus}</td>
              </tr>

              <tr>
                <td style={{fontWeight:"bold"}}>June{billData?.juneYear}</td>
                <td>{billData?.junementcharges}</td>
                <td>{billData?.billamountjune}</td>
                <td>{billData?.juneStatus}</td>
              </tr>

              <tr>
                <td style={{fontWeight:"bold"}}>July{billData?.julyYear}</td>
                <td>{billData?.julymentcharges}</td>
                <td>{billData?.billamountjuly}</td>
                <td>{billData?.julyStatus}</td>
              </tr>

              <tr>
                <td style={{fontWeight:"bold"}}>Aug{billData?.augYear}</td>
                <td>{billData?.augmentcharges}</td>
                <td>{billData?.billamountaug}</td>
                <td>{billData?.augStatus}</td>
              </tr>

              <tr>
                <td style={{fontWeight:"bold"}}>Sep{billData?.sepYear}</td>
                <td>{billData?.sepmentcharges}</td>
                <td>{billData?.billamountsep}</td>
                <td>{billData?.sepStatus}</td>
              </tr>

              <tr>
                <td style={{fontWeight:"bold"}}>Oct{billData?.octYear}</td>
                <td>{billData?.octmentcharges}</td>
                <td>{billData?.billamountoct}</td>
                <td>{billData?.octStatus}</td>
              </tr>

              <tr>
                <td style={{fontWeight:"bold"}}>Nov{billData?.novYear}</td>
                <td>{billData?.novmentcharges}</td>
                <td>{billData?.billamountnov}</td>
                <td>{billData?.novStatus}</td>
              </tr>

              <tr>
                <td style={{fontWeight:"bold"}}>Dec{billData?.decYear} </td>
                <td>{billData?.decmentcharges}</td>
                <td>{billData?.decmentcharges}</td>
                <td>{billData?.decStatus}</td>
              </tr>
            </table>
          </div>
        </div>

        <div className="bill-history" style={{ marginTop: "-295px" }}>
          <div className="section" style={{ width: "66.8%" }}>
            <table style={{ border: "1px solid ", height: "245px" }}>
              <tr>
                <th style={{ width: "281px", marginTop: "-200px" }}>
                  Maintenance Charges
                </th>
              </tr>

              <tr>
                <td style={{ marginLeft: "132px" ,fontWeight:'bold' }}>
                  Water
                  Charges&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Rs{" "}
                  {billData?.watercharges}
                </td>
              </tr>
              <tr>
                <td style={{ marginLeft: "132px" ,fontWeight:'bold' }}>
                  Services Charges
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Rs{" "}
                  {billData?.mentcharges}
                </td>
              </tr>
              <tr>
                <td style={{fontWeight:'bold' }}>
                  Other
                  charges&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Rs{" "}
                  {billData?.othercharges}
                </td>
              </tr>
              <tr>
                <td style={{fontWeight:'bold' }}>
                  Advance
                  &nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Rs{" "}
                  {billData.advance}
                </td>
              </tr>
              <tr>
                <td style={{fontWeight:'bold' }}>
                  Insurance &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Rs{" "}
                  {billData?.insurance}
                </td>
              </tr>
              <tr>
                <td style={{fontWeight:'bold' }}>
                  Bill
                  Adjustment&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Rs{" "}
                  {billData?.adjustment}
                </td>
              </tr>
              <tr>
                <td style={{fontWeight:'bold' }}>
                  Maint/Water
                  +GST&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Rs{" "}
                  {billData?.billamountdec}
                </td>
              </tr>
              <tr>
                <td style={{fontWeight:'bold' }}>
                  Arrears
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  Rs {billData?.arrears}
                </td>
              </tr>
              <tr >
                {" "}
                <td >
                  <label style={{ fontWeight: "bold" }}>Bill Adjustment</label>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Rs{" "}
                  {billData?.adjustment}
                </td>
              </tr>
              <tr>
                {" "}
                <td style={{ fontWeight: "bold" }}>
                  {" "}
                  LP Surcharge
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Rs{" "}
                  {billData?.surcharges}
                </td>
              </tr>
              <tr>
                <td style={{ backgroundColor: "Grey",fontWeight:'bold' }}>
                  within due
                  date&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  Rs {billData?.amountDueDate}
                </td>
              </tr>
              <tr>
                <td style={{ backgroundColor: "Grey" ,fontWeight:'bold' }}>
                  After Due Date
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  Rs {billData?.amountAfterDate}
                </td>
              </tr>
            </table>
            <div style={{ marginTop: "-10px", width: "100%" }}>
              <div
                style={{
                  border: "1px solid ",
                  width: "149.5%",
                  marginTop: "0px",
                  height: "140px",
                  fontStyle: "Verdana",
                  fontSize: "14px",
                }}
              >
                Kuickpay Refrence 01010{billData?.newRefrence}
                <br />
                1.For queries regarding Services Bill Phase 1 to 6 Only Please Dial 5733277{" "}
                <br />
                2.For queries regarding Services Bill Phase 7 to 8 Only Please Dial 5410387
                <br />
                3.Monthly charges of Flats/Appts/Shops/Office/Resturant are charged from
                1st April-2014
                <br />
                4.Monthly Services Charges:On occupation of house we providing security,
                fire brigade,garbage lifting,outside lawn mantenance & other utilites to our
                resident for which charges
                {/* <img src={Image4} style={{ width: '100%' }} /> */}
                {/* <img src={Image4} style={{ width: '100%' }} /> */}
              </div>

              {/*
              <div
                style={{
                  border: "1px solid ",
                  width: "100%",
                  height: '190px',

                }}
              >

              </div> */}
            </div>
          </div>
        </div>

        {/* <div className="bill-his-section" style={{ marginTop: '-50px' }}>

        </div> */}

        {/* < */}

        <div className="section" style={{ marginTop: "-40px" }}>
          <table style={tableStyle}>
            <tr>
              <td style={tdStyle}>Bank Copy</td>
              <td
                style={{
                  fontWeight: "bold",
                  colspan: "4",
                  border: "1px solid ",
                }}
              >
                Bahria Town Rawalpindi - Maintenance Bill
              </td>
              <td style={{fontWeight:'bold',textAlign:'center'}}>Refrence Number</td>
              <td style={tdStyle} colspan="2">
                {billData?.newRefrence}
              </td>
            </tr>
            <tr>
              <td style={tdStyle}>{billData?.customer_Name}</td>
              <td style={tdStyle}>
                {billData?.plot_Number}/ {billData?.street_Number} /
                {billData?.plot_Number}{" "}
              </td>
              <td style={tdStyle}>Meter Number</td>
              <td style={tdStyle} colspan="2">
                {billData?.meterNo}
              </td>
            </tr>

            <tr>
              <td style={tdStyle}>Bill Month</td>
              <td style={tdStyle}>Due Date</td>
              {/* <td style={tdStyle}>Barcode No.</td> */}
              <td style={tdStyle}>Payment Within due date</td>
              <td style={tdStyle}>Payment after Due Date</td>
            </tr>
            <tr>
              <td style={tdStyle}>{billData?.billingMonth} 2023</td>
              <td style={tdStyle}>{formatDateToDDMMYY(billData?.dateDue)}</td>
              {/* <td style={tdStyle}>{billData[0]?.REFRENCENOBARCODE}</td> */}
              <td style={tdStyle}>{billData?.amountDueDate}</td>
              <td style={tdStyle}>{billData?.amountAfterDate}</td>
            </tr>

            <tr>
              <td colspan="5" style={tdStyle}>
                --------------------------------------------------------------------------
                CUT HERE
                -----------------------------------------------------------------------------
              </td>
            </tr>

            <tr>
              <td style={tdStyle}>Bank Copy</td>
              <td
                style={{
                  fontWeight: "bold",
                  colspan: "4",
                  border: "1px solid ",
                }}
              >
                Bahria Town Rawalpindi - Maintenance Bill
              </td>
              <td style={{fontWeight:'bold',textAlign:'center'}}>Refrence Number</td>
              <td style={tdStyle} colspan="2">
                {billData?.newRefrence}
              </td>
            </tr>
            <tr>
              <td style={tdStyle}>{billData?.customer_Name}</td>
              <td style={tdStyle}>
                {billData?.plot_Number}/ {billData?.street_Number} /
                {billData?.plot_Number}{" "}
              </td>
              <td style={tdStyle}>Meter Number</td>
              <td style={tdStyle} colspan="2">
                {billData?.meterNo}
              </td>
            </tr>

            <tr>
              <td style={tdStyle}>Bill Month</td>
              <td style={tdStyle}>Due Date</td>
              {/* <td style={tdStyle}>Barcode No.</td> */}
              <td style={tdStyle}>Payment Within due date</td>
              <td style={tdStyle}>Payment after Due Date</td>
            </tr>
            <tr>
              <td style={tdStyle}>{billData?.billingMonth} 2023</td>
              <td style={tdStyle}>{formatDateToDDMMYY(billData?.dateDue)}</td>
              {/* <td style={tdStyle}>{billData[0]?.REFRENCENOBARCODE}</td> */}
              <td style={tdStyle}>{billData?.amountDueDate}</td>
              <td style={tdStyle}>{billData?.amountAfterDate}</td>
            </tr>
          </table>
        </div>
        
      </div>
      
    </>
  );
};

export default Bill;
