import React from 'react'
import { Route, Routes } from 'react-router-dom'
import SliderMain from './Bills/SliderMain'
import NetMetering from './Bills/NetMetering'
import Footer from './footer/FooterCurrent'
import AboutUs from './pages/AboutUs'
import Billing from './Bills/SliderMain'
import HeaderMUI from './header/HeaderMUI'
import {Typography} from '@mui/material'
import HeaderBootStrap from './header/HeaderBootStrap'
import PageII from './pages/PageII'
import ContactUs from '../rwpbilling/Bills/Contactus' 
import MaintainenceBillRWP from '../rwpbilling/Bills/MaintainenceBillRWP'
import ElectricityRWP from '../rwpbilling/Bills/EletricityBillRWP'

 


function RouterHome() {
  const handleSelect = (eventKey) => alert(`selected ${eventKey}`);
  return (
  <>
<HeaderMUI />  
    <Typography style={{ marginTop:'65px' }}>
    <HeaderBootStrap />
    </Typography>   
  
    <Routes >
    <Route exact path='/' element={<SliderMain />} />
    <Route path='/SliderMain' element={<SliderMain />} />
    <Route path='/AboutUs' element={<PageII />} />
    <Route path='/About' element={<AboutUs />} />
    <Route path='/Contact Us' element={<ContactUs />} />
    <Route path='/Billing' element={<Billing />} />
    <Route path='/NetMetering SOPs' element={<NetMetering />} />
    <Route path='/Projects' element={<PageII />} />
    {/* <Route path='/bill' element={<Bill />} /> */}
    <Route path='/MaintainenceBillRWP' element={<MaintainenceBillRWP />} />
    <Route path='/EletricityRwp' element={<ElectricityRWP/>}/>

   </Routes>
   <Footer />

   </>
    )
}
export default RouterHome
